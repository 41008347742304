import React from "react"
import styled from "styled-components"

import { headlineOne } from "../../Utilities/"

const IntroMainTitle = styled.div`
  width: 100%;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }

  h2 {
    ${headlineOne(`#3a3b3b`)};
    text-transform: uppercase;

    .title {
      display: inline-block;
    }

    .sub-title {
      display: block;

      @media (min-width: 768px) {
        margin-left: 7rem;
      }
    }
  }
`

const MainTitle = ({ title, subTitle }) => {
  return (
    <IntroMainTitle>
      <h2>
        <span className="title">{title}</span>
        {subTitle && <span className="sub-title">{subTitle}</span>}
      </h2>
    </IntroMainTitle>
  )
}

export default MainTitle
