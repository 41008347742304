import React from "react"
import styled from "styled-components"

import StandardWrapper from "../../Shared/StandardWrapper"
import ScriptTitle from "../../Shared/ScriptTitle"
import BodyCopy from "../../Shared/BodyCopy"
import { buttonOne } from "../../../Utilities"

const IntroStyled = styled.div`
  position: relative;
  margin-top: -5rem;
  margin-bottom: 5rem;
  z-index: 25;

  @media (min-width: 768px) {
    margin-top: -2.5rem;
  }

  @media (min-width: 1025px) {
    margin-top: -5vw;
  }

  .introWrapper {
    @media (min-width: 768px) {
    }
    @media (min-width: 1025px) {
      max-width: 65vw;
      margin-left: 10vw;
      padding: 0;
    }
  }

  .intro-content {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  .podcastLink {
    width: 100%;

    a {
      ${buttonOne};
    }
  }
`

const Intro = () => {
  return (
    <IntroStyled>
      <StandardWrapper modifierClass="introWrapper">
        <div className="intro-content">
          <ScriptTitle title="<h2 class='title-script'>We believe actively participating our community is key to making change and multiplying our ability to affect change in a positive way.</h2>" />
          <BodyCopy content="<p>We have built a philosophy into our company about doing good while running our business at every step and into everything we do. The better the places we live are, the better business is for everyone. Here are a few ways we have walked the talk in our community.</p>" />
        </div>
      </StandardWrapper>
    </IntroStyled>
  )
}

export default Intro
