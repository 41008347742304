import React from "react"
import CommunityGivingHero from "../../../Elements/Images/CommunityCulture"

const BottomHero = () => {
  return (
    <div>
      <CommunityGivingHero />
    </div>
  )
}

export default BottomHero
