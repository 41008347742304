import React from "react"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const CommunityCultureHeroStyled = styled(BgImg)`
  width: 100%;
  height: 40rem;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    height: 50rem;
  }

  @media (min-width: 1025px) {
    height: 65rem;
  }
`

const getData = graphql`
  {
    communityCulture: file(relativePath: { eq: "communityculture.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const CommunityCulture = props => {
  const data = useStaticQuery(getData)
  const imageData = data.communityCulture.childImageSharp.fluid
  return <CommunityCultureHeroStyled Tag="div" fluid={imageData} />
}

export default CommunityCulture
