import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/Seo/seo"
import HeaderScene from "../../components/PageParts/InTheCommunity/HeaderScene"
import Intro from "../../components/PageParts/InTheCommunity/Intro"
import CommunityNav from "../../components/PageParts/GivingBack/CommunityNav"
import BottomHero from "../../components/PageParts/InTheCommunity/BottomHero"

const inTheCommunity = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="In The Community and our Culture - Switchback Creative."
        description="We believe actively participating our community is key to making change and multiplying our ability to affect change in a positive way.."
        location={props.location.pathname}
        pageImg={props?.data?.metaImage?.publicURL}
      />
      <HeaderScene />
      <Intro />
      <CommunityNav location={props.location.pathname} />
      <BottomHero />
    </Layout>
  )
}

export const communityQuery = graphql`
  {
    metaImage: file(
      relativePath: { eq: "switchback-communitypages-meta.jpg" }
    ) {
      publicURL
    }
  }
`

export default inTheCommunity
